import(/* webpackMode: "eager" */ "/Users/lbh/Documents/private/PeakPalsWebView/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/Users/lbh/Documents/private/PeakPalsWebView/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/lbh/Documents/private/PeakPalsWebView/src/Context/ContextProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/lbh/Documents/private/PeakPalsWebView/src/Provider/AOSProvider/AOSProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationEvents"] */ "/Users/lbh/Documents/private/PeakPalsWebView/src/Provider/navigation-events/navigation-events.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/lbh/Documents/private/PeakPalsWebView/src/Redux/ReduxProvider/ReduxProvider.tsx");
